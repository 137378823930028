"use strict";
/***************************************************************************************************
 * Auto-Generated File - do not modify because all changes will be lost
 **************************************************************************************************/
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StreamingMediaProxyService = void 0;
const HttpService_1 = require("../../code/HttpService");
const SoundbiteApiConfig_1 = require("../../code/SoundbiteApiConfig");
/**
 * Automatically generated endpoint API for the Soundbite.Api.Controllers.StreamingMediaProxyController
 **/
class StreamingMediaProxyServiceClass {
    /**
    * Automatically generated API call
    */
    sbClipManifest(orgRoute, clipRoute, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/mediaproxy/sbhosted/manifest?orgRoute=${encodeURIComponent(orgRoute)}&clipRoute=${encodeURIComponent(clipRoute)}`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    manifest(sourceUrl, token, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/mediaproxy/manifest?sourceUrl=${encodeURIComponent(sourceUrl)}&token=${encodeURIComponent(token)}`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
    /**
    * Automatically generated API call
    */
    manifestPart(sourceUrl, token, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = SoundbiteApiConfig_1.SoundbiteApiConfig.ApiPrefixUrl + `/mediaproxy/manifestpart?sourceUrl=${encodeURIComponent(sourceUrl)}&token=${encodeURIComponent(token)}`;
            const response = yield HttpService_1.HttpService.get(url, options);
            return response;
        });
    }
}
exports.StreamingMediaProxyService = new StreamingMediaProxyServiceClass();
