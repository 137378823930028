"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Player = void 0;
const react_1 = __importStar(require("react"));
const mobx_react_lite_1 = require("mobx-react-lite");
const controls_1 = require("./controls");
const DownloadBtn_1 = require("./controls/DownloadBtn");
const MediaPlayerContextFactory_1 = require("./video/context/MediaPlayerContextFactory");
const SoundScrubber2_1 = require("./SoundScrubber2");
/***************************************************************************************************
 *  Component
 **************************************************************************************************/
/**
 * A simple player with a sound scrubber and play/pause button
 * @param props
 */
exports.Player = (0, mobx_react_lite_1.observer)((props) => {
    //////////[ State ]///////////////////////////////////////////////////////////////////////////////
    var _a, _b, _c, _d;
    const mediaUrl = (_b = (_a = props.clip) === null || _a === void 0 ? void 0 : _a.url) !== null && _b !== void 0 ? _b : props.mediaUrl;
    const [isFirstPlay, setIsFirstPlay] = (0, react_1.useState)(true);
    const [mediaType, setMediaType] = (0, react_1.useState)(MediaPlayerContextFactory_1.MediaPlayerContextFactory.getTypeName(props.clip, props.mediaUrl));
    const context = (0, react_1.useRef)(MediaPlayerContextFactory_1.MediaPlayerContextFactory.getContext(props.clip, props.mediaUrl));
    const isShowDownload = mediaUrl != null && props.allowDownload === true;
    const scrubberWidth = isShowDownload ? "8" : "10";
    //////////[ Initialize ]//////////////////////////////////////////////////////////////////////////
    // Initial loading
    (0, react_1.useEffect)(() => {
        commonContextSetup();
        // Make sure to stop playback if the player unloads
        return () => {
            var _a;
            (_a = context === null || context === void 0 ? void 0 : context.current) === null || _a === void 0 ? void 0 : _a.pause();
        };
    }, []);
    // Reload when when clip or media URL changes
    (0, react_1.useEffect)(() => {
        // Determine whether the clip/media url has changed and setup context appropriately
        const newMediaType = MediaPlayerContextFactory_1.MediaPlayerContextFactory.getTypeName(props.clip, props.mediaUrl);
        if (newMediaType != mediaType) {
            setMediaType(newMediaType);
            const newContext = MediaPlayerContextFactory_1.MediaPlayerContextFactory.getContext(props.clip, props.mediaUrl);
            context.current = newContext;
            commonContextSetup();
        }
    }, [props.clip, props.mediaUrl]);
    (0, react_1.useEffect)(() => {
        if (props.videoElement) {
            if (context.current && mediaUrl) {
                context.current.mediaElement = props.videoElement;
                context.current.load(props.clip, props.mediaUrl, props.autoPlay, props.isPublic);
            }
        }
    }, [props.videoElement]);
    //////////[ Methods ]/////////////////////////////////////////////////////////////////////////////
    /**
     * Responsible for setting common context configurations.  Context must be setup during initial
     * load and when mediaUrl or clip changes. Common logic should be here to avoid duplication and
     * to avoid accidentially implementing in one place but not the other.
     */
    function commonContextSetup() {
        var _a;
        if (context.current) {
            context.current.onMediaError = props.onMediaError;
            context.current.onPlay = () => {
                if (isFirstPlay) {
                    setIsFirstPlay(false);
                    if (props.onFirstPlay) {
                        props.onFirstPlay();
                    }
                }
            };
            if (mediaUrl) {
                if (context.current.hasMediaElement) {
                    context.current.load(props.clip, props.mediaUrl, (_a = props.autoPlay) !== null && _a !== void 0 ? _a : false);
                }
            }
            if (props.onContext) {
                props.onContext(context.current);
            }
        }
    }
    //////////[ Build UI ]////////////////////////////////////////////////////////////////////////////
    function GetScrubber() {
        if (context.current) {
            return react_1.default.createElement(SoundScrubber2_1.Scrubber2, { context: context.current });
        }
        else {
            return react_1.default.createElement("div", null, "No Audio Context");
        }
    }
    return (react_1.default.createElement(controls_1.Loader, { isLoadedWhen: props.mediaUrl != null || props.clip != null, isSmall: true },
        react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement("div", { className: "mx-1 px-0 flex-grow-1 align-self-center" },
                react_1.default.createElement(GetScrubber, null)),
            react_1.default.createElement(controls_1.ShowWhen, { is: isShowDownload && !((_c = context.current) === null || _c === void 0 ? void 0 : _c.hasError) },
                react_1.default.createElement("div", { className: "mx-1 px-0 align-self-center" },
                    react_1.default.createElement(DownloadBtn_1.DownloadBtn, { mediaUrl: mediaUrl, outline: true, asBlob: true }))),
            react_1.default.createElement("div", { className: "ml-1 px-0 align-self-center" },
                react_1.default.createElement(controls_1.PlayButton, { context: context.current, disabled: (_d = context.current) === null || _d === void 0 ? void 0 : _d.hasError })))));
});
